// Include JavaScript libraries

// Node-Lodash
// https://github.com/lodash/lodash
window._ = require('lodash');

// Node-Jquery
// https://github.com/jquery/jquery
window.$ = window.jQuery = require('jquery');

// Node-PopperJS
window.popper = require('@popperjs/core');

// Node-Bootstrap
// https://github.com/twbs/bootstrap
window.bootstrap = require('bootstrap');

// Node-ImagesLoaded
// https://github.com/desandro/imagesloaded
window.imagesloaded = require('imagesloaded');

// Node-QRcode
// https://github.com/soldair/node-qrcode
window.qrcode = require('qrcode');

// Luxon is a library for working with dates and times in JavaScript.
// https://github.com/moment/luxon
window.luxon = require('luxon');

// Masonry - https://masonry.desandro.com/
window.masonry = require('masonry-layout');

// Tabulator - https://tabulator.info/
window.tabulator = require('tabulator-tables').TabulatorFull;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

